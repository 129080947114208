import React from 'react';
import CardSamplePic from "../../assets/images/CardSamplePic.png"
import { useNavigate } from 'react-router-dom';

const UserCard = () => {
    const navigate = useNavigate();
    return (
        <div className='group cursor-pointer p-4 flex bg-slate-100 rounded-lg hover:bg-[#2D4493]' onClick={() => navigate("/view-all-cards")}>
            <div className='h-12 w-24'>
                <img src={CardSamplePic} />
            </div>
            <div className='flex flex-col gap-2'>
                <div className='text-[14px]'>
                    <div className='flex justify-between'>
                        <p className='group-hover:text-white  text-sm text-[#2D4493]'>Prasad Angarapu</p>
                        <p className='group-hover:text-white font-medium text-sm text-black'>45k</p>
                    </div>
                    <p className='font-medium group-hover:text-white'>OMNI BLS/ALS</p>
                </div>
                <p className='text-[13px] group-hover:text-white group-hover:text-opacity-50 text-black text-opacity-50'>
                    It is a long established fact that a reader It is a long established
                </p>
                <div className='flex gap-2 mt-1'>
                    <button className='px-2 py-1 text-xs group-hover:text-white group-hover:border-white group-hover:border-opacity-30 text-[#555E61] border border-black rounded-md border-opacity-30'>
                        Message
                    </button>
                    <button className='px-2 py-1 text-xs group-hover:text-white group-hover:border-white group-hover:border-opacity-30 text-[#555E61] border border-black rounded-md border-opacity-30'>
                        View
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UserCard