import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Screens/User/Home/Home';
import Home1 from './Screens/User/Home/Home1';
import Home2 from './Screens/User/Home/Home2';
import Home3 from './Screens/User/Home/Home3';
import Home4 from './Screens/User/Home/Home4';
import Trips from './Screens/User/Trips/Trips';
import AbondonTrips from './Screens/User/AbondonTrips/AbondonTrips';
import AnalyticsPage from './Screens/User/Analytics/AnalyticsPage';
import Drivers from './Screens/Admin/Drivers';
import RegisteredUsers from './Screens/Admin/RegisteredUsers';
import Agents from './Screens/Admin/Agents';
import Services from './Screens/Admin/Services';
import ViewAllCards from './Screens/Admin/ViewAllCards';


function App() {
  const role = "Adminabc";

  const [shortBar, setShortBar] = useState(false);
  return (
    <div >
      <Router>
        <Routes>
          <Route path='/' element={<Home role={role} shortBar={shortBar} setShortBar={setShortBar} />} />
          <Route path='/home' element={<Home1 />} />
          <Route path='/home2' element={<Home2 />} />
          <Route path='/home3' element={<Home3 />} />
          <Route path='/home4' element={<Home4 />} />
          <Route path='/trips' element={<Trips role={role} shortBar={shortBar} setShortBar={setShortBar} />} />
          <Route path='/abondontrips' element={<AbondonTrips role={role} shortBar={shortBar} setShortBar={setShortBar} />} />
          <Route path='/analyticspage' element={<AnalyticsPage role={role} shortBar={shortBar} setShortBar={setShortBar} />} />

          <Route path='/drivers' element={<Drivers role={role} shortBar={shortBar} setShortBar={setShortBar} />} />
          <Route path='/registered-users' element={<RegisteredUsers role={role} shortBar={shortBar} setShortBar={setShortBar} />} />
          <Route path='/agents' element={<Agents role={role} shortBar={shortBar} setShortBar={setShortBar} />} />
          <Route path='/services' element={<Services role={role} shortBar={shortBar} setShortBar={setShortBar} />} />

          <Route path='/view-all-cards' element={<ViewAllCards role={role} shortBar={shortBar} setShortBar={setShortBar} />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;


{/* <Analytics/> */ }
{/* <OptionList/> */ }
{/* <BarChart /> */ }
{/* <DropDown/> */ }
{/* <DataTable/> */ }