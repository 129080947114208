import React from "react";



export const modaldata = [
    {

        drivername: "500010",
        createdDate: '24-Aug-2021 | 19:24',
        vehicleType: '200'
    },
    {

        drivername: "500011",
        createdDate: '2-Jan-2021 | 19:24',
        vehicleType: '400'

    },
    {
        drivername: "500012",
        createdDate: '2-Jan-2021 | 19:24',
        vehicleType: '500'
    },
    {

        drivername: "500013",
        createdDate: '2-Jan-2021 | 19:24',
        vehicleType: '1000'
    },
    {

        drivername: "500014",
        createdDate: '24-Aug-2021 | 19:24',
        vehicleType: '1200'
    },
    {

        drivername: "500015",
        createdDate: '24-Aug-2021 | 19:24',
        vehicleType: '1500'
    },
    

];

export const modalheaders = [
    {
        headerName: "Pincode",
        headerId: "drivername",
    },
    {
        headerName: "Created Date",
        headerId: "createdDate",
    },
    {
        headerName: "Actions",
        headerId: "delete",
    },
];