import React from "react";

export default function InputDropdown({ name,
    value,
    label,
    labelstyle,
    options,
    style,
    onClick
}) {
    return (
        <div>
            <label className={` ${labelstyle} text-[#555E61] font-semibold text-xs`}>{label}</label>
            <select className={`${style} border-[#979AA480] outline-none p-2.5 mt-1 w-full  text-xs  text-[#2B2B2B] rounded-md font-semibold `}  onClick={onClick}>
            <option value={value}>{options}</option>
            </select>
        </div>
    )
}

// import React, { useState } from 'react';
// import btnSort from '../../Assets/Icons/btnSort.png'

// const InputDropdown = ({ label, Option1, Option2, Option3, Option4, Option5, Option6, Option7 }) => {
//     const [selectedOption, setSelectedOption] = useState('');

//     const handleOptionChange = (event) => {
//         setSelectedOption(event.target.value);
//     };

//     const handleImageClick = () => {
//         // Simulate selecting the current option when the image is clicked
//         setSelectedOption(selectedOption);
//     };

//     return (
//         <div>
//             <label className="text-[#555E61] font-semibold text-xs">{label}</label>

//             <select
//                 className="border-[#979AA480] outline-none p-2.5  mt-1 w-full  border-2 text-xs  text-[#2B2B2B] rounded font-semibold"
//                 value={selectedOption}
//                 onChange={handleOptionChange}
//             >
//                 <option>{Option1}</option>
//                 <option>{Option2}</option>
//                 <option>{Option3}</option>
//                 <option>{Option4}</option>
//                 <option>{Option5}</option>
//                 <option>{Option6}</option>
//                 <option>{Option7}</option>
//             </select>
//             <div
//                 className="absolute right-0 top-0 bottom-0 h-full w-6 flex items-center justify-center cursor-pointer"
//                  onClick={handleImageClick}
//             >
//                 <img
//                     src={btnSort}
//                     alt="Custom Dropdown Icon"
//                     className="w-4 h-4 rounded"
//                 />
//             </div>

//         </div>
//     );
// };

// export default InputDropdown;

