import React, { useState } from "react";
import ReactModal from "react-modal";
import wrong from '../../../assets/images/wrong.png'
import Line4 from '../../../assets/images/Line4.png'
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customStyles } from "../../../constants/Constant";
import InputDropdown from "../../../components/Common/InputFields/InputDropdown";


const ServiceTariffModal = (props) => {

    const [show, setShow] = useState(false);
    const [value, setValue] = useState('skkskks');

    const handleClick = () => {
        setShow(!show)
        setValue('Per Kilometer Charge');
    }


    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles}
                onRequestClose={props?.closeModal}

            >
                <div className='w-[43vw] h-[70vh] flex flex-col justify-between '>

                    <div>
                        <div className='p-5 px-3  items-center flex justify-between'>
                            <div>
                                <h1 className='text-lg font-bold'>Add Regions</h1>
                            </div>
                            <div className='text-lg px-3 cursor-pointer' onClick={() => props?.setIsOpen(!props?.isOpen)}>
                                <img src={wrong} />
                            </div>
                        </div>

                        <img className='w-full h-[1px]' src={Line4} />

                        <div className='m-5 w-[40vw] flex flex-col gap-6'>

                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div className="w-1/2">
                                    <InputDropdown label='Type Of Charge' style='border-2' options='Select ' value={value} onClick={handleClick} />
                                </div>
                                <div className="w-1/2">
                                    <InputDropdown label='Vehicle Type' style='border-2' options='Select ' value={value} onClick={handleClick} />
                                </div>
                            </div>


                            {show &&
                                <div className="flex flex-col w-full gap-5">

                                    <div className="flex flex-row w-full gap-3">
                                        <div className="flex flex-row w-full gap-3">
                                            <div><TextInputField label='From KM' placeholder='Enter from KM' /></div>
                                            <div><TextInputField label='To KM' placeholder='Enter to KM' /></div>
                                        </div>

                                        <div className="w-full">
                                            <div><TextInputField label='Per KM Charge' placeholder='Enter Amount' /></div>
                                        </div>
                                    </div>


                                    <div className="flex flex-col gap-2">
                                        <div><p className="text-[11px] font-semibold ">Specifications</p></div>

                                        <div className="flex flex-row w-full gap-3">
                                            <div className="w-1/2"><TextInputField label='Stretcher (Per unit charge)' placeholder='Enter Amount' /></div>
                                            <div className="w-1/2"><TextInputField label='Oxygen (Per litre charge)' placeholder='Enter Amount' /></div>
                                        </div>

                                    </div>

                                    <div className="w-1/2"><TextInputField label='Percentage of commission' placeholder='Enter Amount' /></div>


                                </div>
                            }

                        </div>
                    </div>

                    <div className='p-4 shadow-2xl  '>
                        <div className='flex items-center float-right'>
                            <Button label='Cancel' background={'bg-slate-300 px-6'} textcolor={'text-slate-600'} />
                            <Button label='Add' background={'bg-[#F15A2A] px-8'} textcolor={'text-white'} />
                        </div>

                    </div>

                </div>


            </ReactModal>

        </div>
    )
}

export default ServiceTariffModal;