import React, { useState } from "react";
import NavBar from "../../../components/Universal/NavBar";
import Header from "../../../components/Universal/Header";
import Tab from "../../../components/Common/Tabs/Tab";
import UpcomingTrips from "../../../components/Common/Sidebar/QuickBookings/UpcomingTrips";
import TableImg from "../../../assets/images/tableImg.png";
import DynamicTable from "../../../components/Universal/DynamicTable";
import ReactModal from "react-modal";
import { customStyles } from "../../../constants/Constant";
import TextField from "../../../components/Common/InputFields/TextField";
import ProfileCircle from "../../../assets/icons/profilecircle.svg"

const Trips = ({ role }) => {

    const [activeTab, setActiveTab] = useState(1);

    const [showProfileModal, setShowProfileModal] = useState(false);

    const handleTabChange = (data) => {
        setActiveTab(data)
    }

    const data = [
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Scheduled",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Scheduled",
            actions: 'Scheduled'
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "In-Progress",
            createdDate: '24-Aug-2021 | 19:24',
            call: "In-Progress",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
    ];

    const headers = [
        {
            headerName: "Type of trip",
            headerId: "typeOfTrip",
        },
        {
            headerName: "Vehicle Type",
            headerId: "vehicleType",
        },
        {
            headerName: "Status",
            headerId: "status",
        },
        {
            headerName: "Created Date",
            headerId: "createdDate",
        },
        {
            headerName: "Call",
            headerId: "call",
        },
        {
            headerName: "Actions",
            headerId: "actions",
        },
    ];

    return (
        <div className='flex bg-zinc-200'>
            <NavBar active={"Trips"} role={role} />
            <div className='flex w-full bg-pinl-400'>
                <div className='w-full'>
                    <Header title={"Trips"} />
                    <div className='flex h-[88vh] w-full pl-3 pr-4'>
                        <div className='rounded-xl mb-1 w-full bg-white p-6'>
                            <DynamicTable
                                data={data}
                                headers={headers}
                                search={true}
                                Dropdown={true}
                                option1='Non Emergency'
                                width={"w-42"}
                                heightDesktop={"h-[50%]"}
                                nthChildWidth={"[&>*:nth-child(1)]:w-36 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-36 [&>*:nth-child(4)]:w-52 [&>*:nth-child(5)]:w-28  [&>*:nth-child(6)]:w-32"}
                                nthChildStyle={
                                    ""
                                }
                                handleToggleClick={(row, isActive) => {
                                    console.log(isActive, row);
                                }}
                                handleEyeClick={(row) => {
                                    console.log(row)
                                    setShowProfileModal(preState => !preState)
                                }}
                                handleEditClick={(row) => {
                                    console.log(row);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='w-1/3  bg-white p-4  flex flex-col gap-2 overflow-auto h-[100vh]'>
                    <div className="mb-1"> <Tab Tab1={'Upcoming trips'} Tab2={'Abandon Trips'} handleTabClick={handleTabChange} activeTab={activeTab}
                    /></div>
                    {activeTab === 1 ?
                        <div className="flex flex-col gap-2">
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>

                        </div>
                        :
                        <div className="flex flex-col gap-2">
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} phoneStyle={'hidden'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} phoneStyle={'hidden'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} phoneStyle={'hidden'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} phoneStyle={'hidden'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} phoneStyle={'hidden'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>

                        </div>
                    }
                </div>
            </div>
            <ReactModal
                isOpen={showProfileModal}
                onRequestClose={() => setShowProfileModal(preState => !preState)}
                style={customStyles}
            >
                <div className="w-[50vw] max-h-[80vh]">
                    <div className="flex justify-between items-center py-4 px-8 border-b">
                        <div className="flex p-1 rounded-md gap-1 bg-green-200 text-sm">
                            <p>
                                Completed Successfull
                            </p>
                        </div>
                        <p>x</p>
                    </div>
                    <div className="flex justify-between items-center pt-8 mb-6 px-6 ">
                        <div className="flex justify-between w-full">
                            <div className="flex flex-col justify-between gap-3">
                                <p className="text-xl font-semibold">
                                    Non Emergency
                                </p>
                                <p className="text-sm text-black text-opacity-40">
                                    18 Auguest 2023
                                </p>
                            </div>
                            <div className="flex items-center mb-6 gap-2">
                                <div className="w-14 h-14 bg-gray-400 rounded-lg ">

                                </div>
                                <div className="flex flex-col justify-between">
                                    <p className="font-semibold">
                                        Rajesh Koduri
                                    </p>
                                    <p className="text-xs text-black text-opacity-40">
                                        12 Hrs 34 min
                                    </p>
                                    <div className="flex gap-1">
                                        <div></div>
                                        <p className="text-xs text-black text-opacity-40">
                                            4.8
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center px-6 pb-3 border-b">
                        <div className="flex flex-col gap-3">
                            <p className="text-black text-opacity-40 text-xs">
                                VEHICLE TYPE
                            </p>
                            <div className="flex gap-2 items-center">
                                <div className="h-12 w-12 bg-slate-400 rounded-lg">

                                </div>
                                <p className="text-xs">
                                    OMNI BLS
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-6 h-full">
                            <p className="text-black text-opacity-40 text-xs">
                                TOTAL KMS
                            </p>
                            <p className="text-2xl">
                                268
                            </p>

                        </div>
                        <div className="flex flex-col gap-6 h-full">
                            <p className="text-black text-opacity-40 text-xs">
                                TOTAL AMOUNT
                            </p>
                            <p className="text-2xl">
                                8000
                            </p>

                        </div>
                    </div>

                    <div className="p-6">
                        <div className='flex gap-4 items-start w-full'>
                            <img src={ProfileCircle} />
                            <div className='flex flex-col gap-6 w-full'>
                                <p className='font-semibold text-sm '>Personal Details</p>
                                <div className='flex w-full gap-6'>
                                    <TextField
                                        label={"First Name"}
                                        value={"Prasad"}
                                    />
                                    <TextField
                                        label={"First Name"}
                                        value={"Prasad"}
                                    />
                                </div>
                                <div className='flex w-full gap-6'>
                                    <TextField
                                        label={"First Name"}
                                        value={"Prasad"}
                                    />
                                    <TextField
                                        label={"First Name"}
                                        value={"Prasad"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-6">
                        <div className='flex gap-4 items-start w-full'>
                            <img src={ProfileCircle} />
                            <div className='flex flex-col gap-6 w-full'>
                                <p className='font-semibold text-sm '>Personal Details</p>
                                <div className='flex w-full h-52 bg-slate-400 rounded-lg p-6'>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end py-4">
                            <p className="text-xs text-blue-800 font-semibold underline">
                                Track Details
                            </p>
                        </div>
                    </div>
                </div>

            </ReactModal>
        </div>
    )
}

export default Trips;