import React from "react";
import ReactModal from "react-modal";
import wrong from '../../../assets/images/wrong.png'
import Line4 from '../../../assets/images/Line4.png'
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customStyles } from "../../../constants/Constant";


const ServiceAddRegionmodal = (props) => {

    
    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles}
                onRequestClose={props?.closeModal}

            >
                <div className='w-[50vw] h-[70vh] flex flex-col justify-between '>

                    <div>
                        <div className='p-5 px-3  items-center flex justify-between'>
                            <div>
                                <h1 className='text-lg font-bold'>Add Regions</h1>
                            </div>
                            <div className='text-lg px-3 cursor-pointer' onClick={() => props?.setIsOpen(!props?.isOpen)}>
                                <img src={wrong} />
                            </div>
                        </div>

                        <img className='w-full h-[1px]' src={Line4} />

                        <div className='m-5 w-full flex flex-col gap-2'>
                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div><TextInputField label='Enter Region Name' placeholder='Hyderabad' /></div>
                                <div><TextInputField label='Enter Pin-code' placeholder='Enter Pin-code' /></div>

                                <div className='mt-6'>
                                    <Button label='Add to list' background={'bg-white py-3.5 border-[1px] text-orange-500 border-orange-500'} />
                                </div>
                            </div>

                            <div>
                                <div className=' w-[46vw] p-4 border-2 border-[#979AA480] flex flex-row gap-2 pr-40 rounded-lg pb-12'>

                                    <div className='bg-[#979AA480] rounded-md flex flex-row items-center p-1 px-3 gap-3'>
                                        <div> <p className='text-[12px] text-blue-900 font-semibold'>500064</p> </div>
                                        <div className='opacity-60 pb-0.5'> x </div>
                                    </div>

                                    <div className='bg-[#979AA480] rounded-md flex flex-row items-center p-1 px-3 gap-3'>
                                        <div> <p className='text-[12px] text-blue-900 font-semibold'>500064</p> </div>
                                        <div className='opacity-60 pb-0.5'> x </div>
                                    </div>

                                    <div className='bg-[#979AA480] rounded-md flex flex-row items-center p-1 px-3 gap-3'>
                                        <div> <p className='text-[12px] text-blue-900 font-semibold'>500064</p> </div>
                                        <div className='opacity-60 pb-0.5'> x </div>
                                    </div>

                                    <div className='bg-[#979AA480] rounded-md flex flex-row items-center p-1 px-3 gap-3'>
                                        <div> <p className='text-[12px] text-blue-900 font-semibold'>500019</p> </div>
                                        <div className='opacity-60 pb-0.5'> x </div>
                                    </div>

                                    <div className='bg-[#979AA480] rounded-md flex flex-row items-center p-1 px-3 gap-3'>
                                        <div> <p className='text-[12px] text-blue-900 font-semibold'>500064</p> </div>
                                        <div className='opacity-60 pb-0.5'> x </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='p-4 shadow-2xl  '>
                        <div className='flex items-center float-right'>
                            <Button label='Cancel' background={'bg-slate-300 px-6'} textcolor={'text-slate-600'} />
                            <Button label='Add' background={'bg-[#F15A2A] px-8'} textcolor={'text-white'} />
                        </div>

                    </div>

                </div>


            </ReactModal>

        </div>
    )
}

export default ServiceAddRegionmodal;