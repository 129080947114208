import React from "react";
import ReactModal from "react-modal";
import wrong from '../../../assets/images/wrong.png'
import Line4 from '../../../assets/images/Line4.png'
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customStyles } from "../../../constants/Constant";
import TextInput from "../../../components/Common/InputFields/TextInput";


const ServiceAddOnmodal = (props) => {


    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles}
                onRequestClose={props?.closeModal}

            >
                <div className='w-[40vw] h-[70vh] flex flex-col justify-between '>

                    <div>
                        <div className=' p-5 px-3  items-center flex justify-between'>
                            <div>
                                <h1 className='text-lg font-bold'>Add New Add Ons</h1>
                            </div>
                            <div className='text-lg px-3 cursor-pointer' onClick={() => props?.setIsOpen(!props?.isOpen)}>
                                <img src={wrong} />
                            </div>
                        </div>

                        <img className='w-full h-[1px]' src={Line4} />

                        <div className='m-5 w-[37vw] flex flex-col gap-5'>
                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div className="w-full"><TextInputField label='Add On' placeholder='Enter Add On' /></div>
                                <div className="w-full"><TextInputField label='Measurement' placeholder='Enter measurement' /></div>
                            </div>

                            <div>
                                <div className="w-full "><TextInputField label='Description' placeholder='Enter description' inputstyle={'pb-14'} /></div>

                            </div>

                            <div>
                            <p className="text-[11px] font-medium">Upload Add On icon</p>
                            <div className="w-full py-12 mt-1 bg-slate-100 rounded-md flex justify-center items-center">
                                <div><p className="text-[10px]">Browse files to upload</p></div>
                            </div>
                            </div>

                        </div>
                    </div>

                    <div className='p-4 shadow-2xl  '>
                        <div className='flex items-center float-right'>
                            <Button label='Cancel' background={'bg-slate-300 px-6'} textcolor={'text-slate-600'} />
                            <Button label='Add' background={'bg-[#F15A2A] px-8'} textcolor={'text-white'} />
                        </div>

                    </div>

                </div>


            </ReactModal>

        </div>
    )
}

export default ServiceAddOnmodal;