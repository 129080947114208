import React from "react";
import ReactModal from "react-modal";
import wrong from '../../../assets/images/wrong.png'
import Line4 from '../../../assets/images/Line4.png'
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customStyles } from "../../../constants/Constant";
import InputDropdown from "../../../components/Common/InputFields/InputDropdown";
import Switch from '../../../assets/images/Switch.png'


const ServiceDiscountModal = (props) => {


    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles}
                onRequestClose={props?.closeModal}

            >
                <div className='w-[45vw] h-[80vh] flex flex-col justify-between '>

                    <div>
                        <div className='p-5 px-3  items-center flex justify-between'>
                            <div>
                                <h1 className='text-lg font-bold'>Add Regions</h1>
                            </div>
                            <div className='text-lg px-3 cursor-pointer' onClick={() => props?.setIsOpen(!props?.isOpen)}>
                                <img src={wrong} />
                            </div>
                        </div>

                        <img className='w-full h-[1px]' src={Line4} />

                        <div className='m-5 w-[42vw] flex flex-col gap-5'>

                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div className="w-1/2 "><InputDropdown label='Type of Discount' style='border-2 text-slate-400' options='Select' /></div>
                                <div className="w-1/2"><TextInputField label='Percentage Value' placeholder='Enter here' /></div>
                            </div>

                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div className="w-1/2 "><InputDropdown label='Type of Charge' style='border-2 text-slate-400' options='Select' /></div>
                                <div className="w-1/2"><InputDropdown label='Discount Value' style='border-2 text-slate-400' options='Select' /></div>
                            </div>

                            <div className="w-full ">
                                <TextInputField label='Description' placeholder='Enter description' inputstyle={'pb-12'} />
                            </div>

                            <div className="w-1/2">
                                <InputDropdown label='Discount Value' style='border-2 text-slate-400' options='Select' />
                            </div>

                            <div className="flex gap-3 items-center">
                             <img src={Switch}/> <p className="text-[11px] font-semibold text-[#555E61]">Discount available for call center agents</p>
                            </div>

                        </div>
                    </div>

                    <div className='p-4 shadow-2xl  '>
                        <div className='flex items-center float-right'>
                            <Button label='Cancel' background={'bg-slate-300 px-6'} textcolor={'text-slate-600'} />
                            <Button label='Add' background={'bg-[#F15A2A] px-8'} textcolor={'text-white'} />
                        </div>
                    </div>

                </div>


            </ReactModal>

        </div>
    )
}

export default ServiceDiscountModal;