import React from "react";
import ReactModal from "react-modal";
import wrong from '../../../assets/images/wrong.png'
import Line4 from '../../../assets/images/Line4.png'
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customStyles } from "../../../constants/Constant";
import InputDropdown from "../../../components/Common/InputFields/InputDropdown";
import Switch from '../../../assets/images/Switch.png'
import TextInput from "../../../components/Common/InputFields/TextInput";


const ServiceDiscountModal2 = (props) => {


    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles}
                onRequestClose={props?.closeModal}

            >
                <div className='w-[45vw] h-[75vh] flex flex-col justify-between '>

                    <div>
                        <div className='p-5 px-3  items-center flex justify-between'>
                            <div>
                                <h1 className='text-lg font-bold'>Add Regions</h1>
                            </div>
                            <div className='text-lg px-3 cursor-pointer' onClick={() => props?.setIsOpen(!props?.isOpen)}>
                                <img src={wrong} />
                            </div>
                        </div>

                        <img className='w-full h-[1px]' src={Line4} />

                        <div className='m-5 w-[42vw] flex flex-col gap-8'>

                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div className="w-1/2"><TextInput label='Type Of Discount' value='First Time Customer' /></div>
                                <div className="w-1/2"><TextInput label='Percentage Value' value='15%' /></div>
                            </div>

                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div className="w-1/2"><TextInput label='Type Of Charge' value='Fixed Price' /></div>
                                <div className="w-1/2"><TextInput label='Discount Value' value='Percentage' /></div>
                            </div>

                            <div className="w-full ">
                                <div className="pl-2"><p className="text-[#555E61] font-semibold text-[11px]">{'Description'}</p></div>
                                <div className=" border-[#979AA480] outline-none p-2.5 mt-1 w-full  border-none text-xs text-[#2B2B2B]  font-semibold"><p>Offer Valids for first time customer & valid till for one month</p>
                                </div>
                                <img className="w-full" src={Line4}  />

                            </div>

                            <div className="w-1/2">
                                <TextInput label='Region' value='Hyderabad' />
                            </div>

                            <div className="flex gap-3 items-center">
                                <img src={Switch} /> <p className="text-[11px] font-semibold text-[#555E61]">Discount available for call center agents</p>
                            </div>

                        </div>
                    </div>

                    <div className='p-4 shadow-2xl  '>
                        <div className='flex items-center float-right'>
                            <Button label='Cancel' background={'bg-slate-300 px-6'} textcolor={'text-slate-600'} />
                            <Button label='Add' background={'bg-[#F15A2A] px-8'} textcolor={'text-white'} />
                        </div>
                    </div>

                </div>


            </ReactModal>

        </div>
    )
}

export default ServiceDiscountModal2;