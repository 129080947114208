import React from 'react'
import CardSamplePic from "../../assets/images/CardSamplePic.png"
import HoverPin from "../../assets/icons/HoverPin.svg"
import Pin from "../../assets/icons/Pin.svg"

const CardOfViewAll = ({
    id,
    handleChange,
    selected
}) => {

    return (
        <div
            onClick={() => handleChange(id)}
            className={
                id === selected ?
                    'cursor-pointer p-4 flex rounded-lg gap-2 bg-[#2D4493]'
                    :
                    'group cursor-pointer p-4 flex bg-slate-100 rounded-lg gap-2 hover:bg-blue-50'
            }>
            <div className='flex flex-col gap-4 items-center'>
                <img className="h-12 w-20 rounded-full" src={CardSamplePic} />
                <img src={HoverPin} />
            </div>
            <div className='flex flex-col gap-2'>
                <div className='text-[14px]'>
                    <div className='flex justify-between'>
                        <p className={
                            id === selected ?
                                "text-white text-sm"
                                :
                                ' text-sm text-[#2D4493]'
                        }>
                            Prasad Angarapu
                        </p>
                        <p className={
                            id === selected ?
                                "text-white font-medium text-xs text-opacity-40"
                                :
                                ' font-medium text-xs text-black text-opacity-40'
                        }>2 Hrs ago</p>
                    </div>
                    <p className={
                        id === selected ?
                            "font-medium text-white"
                            :
                            'font-medium'
                    }>
                        OMNI BLS/ALS
                    </p>
                </div>
                <p className={
                    id === selected ?
                        "text-[13px] text-white text-opacity-50"
                        :
                        'text-[13px]  text-black text-opacity-50'
                }>
                    It is a long established fact that a reader It is a long established fact that a reader reader It is a long established fact that a reader
                </p>
            </div>
        </div>
    )
}

export default CardOfViewAll