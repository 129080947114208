import React, { useState } from 'react'
import InnerTab from '../../components/Common/Tabs/InnerTab'
import CardOfViewAll from '../../components/ViewAllCards/CardOfViewAll'
import NavBar from '../../components/Universal/NavBar';
import Header from '../../components/Universal/Header';
import CardSamplePic from "../../assets/images/CardSamplePic.png"
import ProfileCircle from "../../assets/icons/profilecircle.svg"
import ProfileCircle2 from "../../assets/icons/profilecircle-1.svg"
import Button from '../../components/Common/Buttons/Button';
import TextField from '../../components/Common/InputFields/TextField';

const ViewAllCards = ({ role }) => {
    const [activeTab, setActiveTab] = useState("Personal Details");
    const [selected, setSeleted] = useState(0);

    const TransactionsCard = ({
        month,
        day,
        rechargeAmount,
        tripAmount,
        tripCommision,
        balanceAmount
    }) => {
        return (
            <div className='bg-slate-100 rounded-2xl w-full flex justify-between items-center p-4 mb-1'>
                <div className='flex flex-col justify-center items-center p-2 bg-white rounded-md text-xs'>
                    <p className='font-semibold '>
                        {day}
                    </p>
                    <p className='text-black text-opacity-25'>
                        {month}
                    </p>
                </div>
                <div className='flex gap-10'>
                    <div className='flex flex-col justify-center items-start gap-2 text-sm'>
                        <p className=' text-black text-opacity-40'>
                            Recharge Amount
                        </p>
                        <p className='text-black font-semibold text-base'>
                            {rechargeAmount}
                        </p>
                    </div>
                    <div className='flex flex-col justify-center gap-2 items-start text-sm'>
                        <p className=' text-black text-opacity-40'>
                            Trip Amount
                        </p>
                        <p className='text-black font-semibold text-base'>
                            {tripAmount}
                        </p>
                    </div>
                    <div className='flex flex-col justify-center gap-2 items-start text-sm'>
                        <p className=' text-black text-opacity-40'>
                            Trip Commision
                        </p>
                        <p className='text-black font-semibold text-base'>
                            {tripCommision}
                        </p>
                    </div>
                </div>
                <div className='flex flex-col justify-center items-end gap-2 text-sm'>
                    <p className=' text-black text-opacity-40'>
                        Recharge Amount
                    </p>
                    <p className='text-green-500 font-semibold text-base'>
                        {balanceAmount}
                    </p>
                </div>
            </div>
        )
    }

    const Tab1 = () => {
        return (
            <div className='flex flex-col gap-6 pt-6 w-full h-[62vh] overflow-auto'>
                <div className='flex gap-4 items-start w-full'>
                    <img src={ProfileCircle} />
                    <div className='flex flex-col gap-6 w-full'>
                        <p className='font-semibold text-sm '>Personal Details</p>
                        <div className='flex w-full gap-6'>
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                        </div>
                        <div className='flex w-full gap-6'>
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                        </div>
                        <div className='flex w-full gap-6'>
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                        </div>
                        <div className='flex w-[49%] gap-6'>
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />

                        </div>
                    </div>
                </div>
                <div className='flex gap-4 items-start w-full'>
                    <img src={ProfileCircle2} />
                    <div className='flex flex-col gap-6 w-full'>
                        <p className='font-semibold text-sm '>Emergency Contact 1</p>
                        <div className='flex w-full gap-6'>
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                        </div>
                        <div className='flex w-full gap-6'>
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                        </div>
                        <div className='flex w-full gap-6'>
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                        </div>
                        <div className='flex w-[49%] gap-6'>
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />

                        </div>
                    </div>
                </div>
                <div className='py-4 mt-8 bg-white shadow-lg text-xs flex justify-between items-center border-t'>
                    <button className='text-blue-800 font-semibold'>
                        Do it Later
                    </button>
                    <div className='flex'>
                        <Button
                            background={"bg-gray-300 text-xs"}
                            label={"REJECT"}
                            textcolor={"text-gray-600"}
                        />
                        <Button
                            background={"bg-[#F15A2A] text-xs"}
                            label={"CONFIRM & ACCEPT"}
                            textcolor={"text-white"}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const Tab2 = () => {
        return (
            <div className='p-5 flex flex-col w-full h-[62vh] overflow-auto'>

            </div>
        )
    }

    const Tab3 = () => {
        return (
            <div className='pt-6 flex flex-col w-full h-[62vh] overflow-auto'>
                <div className='flex gap-4 pb-6 items-start w-full'>
                    <img src={ProfileCircle} />
                    <div className='flex flex-col gap-6 w-full'>
                        <p className='font-semibold text-sm '>Wallet Transactions</p>
                    </div>
                </div>
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
            </div>
        )
    }

    const tabHeaders = [
        'Personal Details',
        'Attachments',
        'Wallets Transactions',
    ]

    const tabData = [
        {
            'Personal Details': <Tab1 />
        },
        {
            'Attachments': <Tab2 />
        },
        {
            'Wallets Transactions': <Tab3 />
        }
    ]
    return (
        <div className='flex bg-zinc-100 h-[100vh]'>
            <NavBar active={"Home"} role={role} />
            <div className='flex flex-col w-full h-full gap-2'>
                <Header title={"Abandon Trip"} />
                <div className='w-full h-full pl-3 pr-4 pb-4'>
                    <div className='flex w-full h-full bg-white rounded-xl'>
                        <div className='flex flex-col  p-4 gap-1 overflow-auto w-[40%] border-r-[2px] border-zinc-200'>
                            <CardOfViewAll id={0} handleChange={setSeleted} selected={selected} />
                            <CardOfViewAll id={1} handleChange={setSeleted} selected={selected} />
                            <CardOfViewAll id={2} handleChange={setSeleted} selected={selected} />
                            <CardOfViewAll id={3} handleChange={setSeleted} selected={selected} />
                            <CardOfViewAll id={4} handleChange={setSeleted} selected={selected} />
                        </div>
                        <div className='w-[60%] h-full'>
                            <p className='p-5 border-b-[2px] border-zinc-200'>Omni BLS</p>
                            <div className='p-5'>
                                <div className='flex gap-4'>
                                    <img className='h-14 w-14 rounded-full' src={CardSamplePic} />
                                    <div className='flex flex-col'>
                                        <p className='text-xl font-semibold'>Prasad Angarapu</p>
                                        <p className='text-xs font-semibold text-black text-opacity-50'>prasadangarapu@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className='px-4'>
                                <InnerTab
                                    active={activeTab}
                                    data={tabData}
                                    headers={tabHeaders}
                                    handleChange={setActiveTab}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewAllCards