import React from "react";


export default function TextInputField ({ placeholder,
    name,
    value,
    label,
    inputstyle
}) {
    return (
        <div>
            <label className="text-[#555E61] font-semibold text-[11px]">{label}</label>
            <input className={` ${inputstyle} border-[#979AA480] outline-none p-2.5 mt-1 w-full  border-2 text-xs text-[#2B2B2B]  rounded-md font-semibold`}
             placeholder={placeholder}
             name={name}
             value={value}></input>
        </div>
    )
}