import React, { useState } from "react";
import NavBar from "../../../components/Universal/NavBar";
import Header from "../../../components/Universal/Header";
import QuickBooking from "../../../components/Common/Sidebar/QuickBookings/QuickBooking";
import QuickBooking2 from "../../../components/Common/Sidebar/QuickBookings/QuickBooking1";

import UserCard from "../../../components/Universal/UserCard";
import Tab from "../../../components/Common/Tabs/Tab";
import { useNavigate } from "react-router-dom";
import ActiveAndInActiveComp from "../../../components/Common/ActiveAndInActiveComp";


const Home = ({ role, shortBar, setShortBar }) => {

    const [activeTab, setActiveTab] = useState(1);

    const handleTabChange = (data) => {
        setActiveTab(data)
    }

    return (
        <div className='flex bg-zinc-200 h-[100vh]'>
            <NavBar active={"Home"} role={role} />
            {
                role === "Admin" ?
                    <div className='flex w-full h-full'>
                        <div className='flex flex-col w-full h-full'>
                            <Header title={"Welcome"} />
                            <div className='w-full h-full pl-3 pr-4 pb-4'>
                                <div className='flex flex-col h-full gap-4 p-4 bg-white rounded-xl'>

                                </div>
                            </div>
                        </div>
                        <ActiveAndInActiveComp
                            shortBar={shortBar}
                            setShortBar={setShortBar}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            handleTabChange={handleTabChange}
                        />
                    </div>
                    :
                    <div className='flex w-full bg-pinl-400'>
                        <div className='w-full'>
                            <Header title={"Welcome"} />
                            <div className='w-full pl-3 pr-4'>
                                <div className='flex flex-col gap-4 p-4 bg-white rounded-xl'>
                                    <p className='text-black font-bold text-lg'>
                                        Booking
                                    </p>
                                    <div className='bg-blue-950 rounded-xl h-[75vh]'>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-1/3 h-full bg-white'>
                            <QuickBooking />
                        </div>
                    </div>
            }
        </div>
    )
}

export default Home;