import React from "react";


export default function Button({ label, onClick, textcolor, background, bordercolor, borderwidth }) {

    return (
        <div
            className={`${background} flex items-center rounded-lg py-2 px-4 m-2 cursor-pointer ${borderwidth} ${bordercolor} `}
            onClick={onClick}>
            <div><p className={`${textcolor}  font-semibold text-xs text-center`}>
                {label}
            </p>
            </div>
        </div>
    )
}

