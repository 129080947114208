
import Homeicon from '../assets/images/home.png'
import Ambulance from '../assets/images/ambulanceicon.png'
import Analyticsicon from '../assets/images/Analytics.png'
import bookmarkicon from '../assets/images/bookmark.png'
import Drives from "../assets/icons/AdminNavbarIcons/Drivers.svg"
import Profile from "../assets/icons/AdminNavbarIcons/Profile.svg"
import Headset from "../assets/icons/AdminNavbarIcons/headset.svg"
import settings from "../assets/icons/AdminNavbarIcons/settings.svg"

export const userNavbarConsts = [
    {
        icon: Homeicon,
        title: "Home",
        route: "/",
    },
    {
        icon: Ambulance,
        title: "Trips",
        route: "/trips",
    },
    {
        icon: bookmarkicon,
        title: "AbondonTrips",
        route: "/abondontrips",
    },
    {
        icon: Analyticsicon,
        title: "AnalyticsPage",
        route: "/analyticspage",
    },
]

export const adminNavbarConsts = [
    {
        icon: Homeicon,
        title: "Home",
        route: "/",
    },
    {
        icon: Drives,
        title: "Drivers",
        route: "/drivers",
    },
    {
        icon: Profile,
        title: "Registered Users",
        route: "/registered-users",
    },
    {
        icon: Headset,
        title: "Agents",
        route: "/agents",
    },
    {
        icon: settings,
        title: "Services",
        route: "/services",
    },
]

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: "0",
        borderRadius: "16px",
    },
};