import React, { useState } from 'react'
import NavBar from '../../components/Universal/NavBar'
import Header from '../../components/Universal/Header'
import ActiveAndInActiveComp from '../../components/Common/ActiveAndInActiveComp'
import InnerTab from '../../components/Common/Tabs/InnerTab'
import DynamicTable from '../../components/Universal/DynamicTable'
import { Tab1data, Tab1headers } from '../../constants/ServicesConstants'
import { Tab2data, Tab2headers } from '../../constants/ServicesConstants'
import { Tab3data, Tab3headers } from '../../constants/ServicesConstants'
import { Tab4data, Tab4headers } from '../../constants/ServicesConstants'
import { Tab5data, Tab5headers } from '../../constants/ServicesConstants'
import ServiceAddRegionmodal from './Modals/ServiceAddRegionmodal'
import ServiceAddRegionmodal2 from './Modals/ServiceAddRegionmodal2'
import ServiceAddOnmodal from './Modals/ServiceAddOnmodal'
import ServiceAddOnmodal2 from './Modals/ServiceAddOnmodal2'
import ServiceAddVehicle from './Modals/ServiceAddVehicle'
import ServiceAddVehicle2 from './Modals/ServiceAddVehicle2'
import ServiceTariffModal from './Modals/ServiceTariffModal'
import ServiceDiscountModal from './Modals/ServiceDiscountModal'
import ServiceDiscountModal2 from './Modals/ServiceDiscountModal2'


const Services = ({ role, shortBar, setShortBar }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [activeInnerTab, setActiveInnerTab] = useState("Regions");

    const [isOpen, setIsOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [addOn, setAddOn] = useState(false);
    const [addOn1, setAddOn1] = useState(false);

    const [addVehicle, setAddVehicle] = useState(false);
    const [addVehicle1, setAddVehicle1] = useState(false);

    const [tariff, setTariff] = useState(false);


    const [discount, setDiscount] = useState(false);
    const [discount1, setDiscount1] = useState(false);


    const handleTabChange = (data) => {
        setActiveTab(data)
    }


    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    }
    const closeModal = () => {
        setIsOpen(false);
    }


    const handleEyeClick = () => {
        setModalOpen(!modalOpen)
    }
    const closeModals = () => {
        setModalOpen(false)
    }

    const handleButtonClick1 = () => {
        setAddOn(!addOn);
    }
    const closeModal1 = () => {
        setAddOn(false);
    }



    const handleEyeClick1 = () => {
        setAddOn1(!addOn1);
    }
    const closeModals1 = () => {
        setAddOn1(addOn1);
    }


    const handleButtonClick2 = () => {
        setAddVehicle(!addVehicle);
    }
    const closeModal2 = () => {
        setAddVehicle(false);
    }

    const handleEyeClick2 = () => {
        setAddVehicle1(!addVehicle1);
    }
    const closeModals2 = () => {
        setAddVehicle(addVehicle);
    }

    const handleButtonClick3 = () => {
        setTariff(!tariff);
    }
    const closeModal3 = () => {
        setTariff(false);
    }

    const handleButtonClick4 = () => {
        setDiscount(!discount);
    }
    const closeModal4 = () => {
        setDiscount(false);
    }

    const handleEyeClick4 = () => {
        setDiscount1(!discount1);
    }
    const closeModals4 = () => {
        setDiscount1(discount1);
    }




    const Tab1 = () => {
        return (
            <div className='mt-5'>

                <DynamicTable
                    data={Tab1data}
                    headers={Tab1headers}
                    search={true}
                    button={true}
                    handleButtonClick={handleButtonClick}
                    title='Regions'
                    label='Add New Region'
                    width={"w-42"}
                    heightDesktop={"h-[50%]"}
                    nthChildWidth={"[&>*:nth-child(1)]:w-40 [&>*:nth-child(2)]:w-56 [&>*:nth-child(3)]:w-40 [&>*:nth-child(4)]:w-32 [&>*:nth-child(5)]:w-32"}
                    nthChildStyle={
                        ""
                    }
                    handleEyeClick={() => handleEyeClick()}

                />


            </div>
        )
    }

    const Tab2 = () => {
        return (
            <div className='mt-5'>

                <DynamicTable
                    data={Tab2data}
                    headers={Tab2headers}
                    search={true}
                    option1='All'
                    title='Add Ons'
                    button={true}
                    handleButtonClick={handleButtonClick1}
                    buttonstyle='py-2'
                    label='Add New Add Ons'
                    width={"w-42"}
                    heightDesktop={"h-[50%]"}
                    nthChildWidth={"[&>*:nth-child(1)]:w-44 [&>*:nth-child(2)]:w-56 [&>*:nth-child(3)]:w-44 [&>*:nth-child(4)]:w-32 [&>*:nth-child(5)]:w-32"}
                    nthChildStyle={
                        ""
                    }
                    handleToggleClick={(row, isActive) => {
                        console.log(isActive, row);
                    }}
                    handleEyeClick={(row) => handleEyeClick1()}

                    handleEditClick={(row) => {
                        console.log(row);
                    }}
                />


            </div>
        )
    }

    const Tab3 = () => {
        return (
            <div className='mt-5'>

                <DynamicTable
                    data={Tab3data}
                    headers={Tab3headers}
                    search={true}
                    option1='All'
                    title='Vehicle Type'
                    button={true}
                    handleButtonClick={handleButtonClick2}
                    handleEyeClick={() => handleEyeClick2()}
                    buttonstyle='py-2'
                    label='Add New Vehicle'
                    width={"w-42"}
                    heightDesktop={"h-[50%]"}
                    nthChildWidth={"[&>*:nth-child(1)]:w-56 [&>*:nth-child(2)]:w-42 [&>*:nth-child(3)]:w-44 [&>*:nth-child(4)]:w-32 [&>*:nth-child(5)]:w-32"}
                    nthChildStyle={
                        ""
                    }
                    handleToggleClick={(row, isActive) => {
                        console.log(isActive, row);
                    }}
                    handleEditClick={(row) => {
                        console.log(row);
                    }}
                />

            </div>
        )
    }

    const Tab4 = () => {
        return (
            <div className='mt-5'>

                <DynamicTable
                    data={Tab4data}
                    headers={Tab4headers}
                    search={true}
                    option1='All'
                    title='Tariff'
                    button={true}
                    buttonstyle='py-2'
                    label='Add New Tariff'
                    handleButtonClick={handleButtonClick3}
                    width={"w-42"}
                    heightDesktop={"h-[50%]"}
                    nthChildWidth={"[&>*:nth-child(1)]:w-44 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-36 [&>*:nth-child(4)]:w-52 [&>*:nth-child(5)]:w-32"}
                    nthChildStyle={
                        ""
                    }
                    handleToggleClick={(row, isActive) => {
                        console.log(isActive, row);
                    }}
                    handleEyeClick={(row) => {
                        console.log(row);
                    }}
                    handleEditClick={(row) => {
                        console.log(row);
                    }}
                />
            </div>

        )
    }

    const Tab5 = () => {
        return (
            <div className='mt-5'>

                <DynamicTable
                    data={Tab5data}
                    headers={Tab5headers}
                    search={true}
                    option1='All'
                    title='Discounts'
                    button={true}
                    handleButtonClick={handleButtonClick4}
                    buttonstyle='py-2'
                    label='Add New Discount'
                    width={"w-42"}
                    heightDesktop={"h-[50%]"}
                    nthChildWidth={"[&>*:nth-child(1)]:w-44 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-40 [&>*:nth-child(4)]:w-44 [&>*:nth-child(5)]:w-32"}
                    nthChildStyle={
                        ""
                    }
                    handleToggleClick={(row, isActive) => {
                        console.log(isActive, row);
                    }}
                    handleEyeClick={(row) =>handleEyeClick4()}

                    handleEditClick={(row) => {
                        console.log(row);
                    }}
                />

            </div>
        )
    }

    const tabHeaders = [
        'Regions',
        'Add Ons',
        'Vehicle Types',
        'Traiff',
        'Discount'
    ]

    const tabData = [
        {
            'Regions': <Tab1 />
        },
        {
            'Add Ons': <Tab2 />
        },
        {
            'Vehicle Types': <Tab3 />
        },
        {
            'Traiff': <Tab4 />
        },
        {
            'Discount': <Tab5 />
        }
    ]
    return (
        <div className='flex bg-zinc-200 h-[100vh]'>
            <NavBar active={"Services"} role={role} />
            <div className='flex w-full h-full'>
                <div className='flex flex-col w-full h-full'>
                    <Header title={"Services"} />
                    <div className='flex h-[88vh]  w-full pl-3  pr-4'>
                        <div className='rounded-xl mb-1 w-full bg-white p-4'>
                            <InnerTab
                                active={activeInnerTab}
                                data={tabData}
                                headers={tabHeaders}
                                handleChange={setActiveInnerTab}
                            />
                        </div>
                    </div>
                </div>
                <ActiveAndInActiveComp
                    shortBar={shortBar}
                    setShortBar={setShortBar}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleTabChange={handleTabChange}
                />
            </div>


            <ServiceAddRegionmodal
                handleButtonClick={handleButtonClick}
                closeModal={closeModal}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
            />
            <ServiceAddRegionmodal2
                closeModals={closeModals}
                setModalOpen={setModalOpen}
                modalOpen={modalOpen}
                handleEyeClick={handleEyeClick}
            />


            <ServiceAddOnmodal
                closeModal={closeModal1}
                setIsOpen={setAddOn}
                isOpen={addOn}
                handleButtonClick={handleButtonClick}
            />
            <ServiceAddOnmodal2
                closeModal={closeModals1}
                setIsOpen={setAddOn1}
                isOpen={addOn1}
                handleButtonClick={handleButtonClick}
            />


            <ServiceAddVehicle
                closeModal={closeModal2}
                setIsOpen={setAddVehicle}
                isOpen={addVehicle}
                handleButtonClick={handleButtonClick2}
            />
            <ServiceAddVehicle2
                closeModal={closeModals2}
                setIsOpen={setAddVehicle1}
                isOpen={addVehicle1}
            />


            <ServiceTariffModal
                closeModal={closeModal3}
                setIsOpen={setTariff}
                isOpen={tariff}
            />


            <ServiceDiscountModal
                closeModal={closeModal4}
                setIsOpen={setDiscount}
                isOpen={discount}
            />
            <ServiceDiscountModal2
                closeModal={closeModals4}
                setIsOpen={setDiscount1}
                isOpen={discount1}
            />






        </div>
    )
}

export default Services