import React, { useState } from 'react'
import NavBar from '../../components/Universal/NavBar'
import Header from '../../components/Universal/Header'
import ActiveAndInActiveComp from '../../components/Common/ActiveAndInActiveComp'
import CardSamplePic from '../../assets/images/CardSamplePic.png'
import DynamicTable from '../../components/Universal/DynamicTable'
import AddAgentModal from './Modals/AgentModal/AddAgentModal'

const Agents = ({ role, shortBar, setShortBar }) => {
    const [activeTab, setActiveTab] = useState(1);

    const [isAgent, setIsAgent] = useState(false);

    const handleButtonClick = (data) => {
        setIsAgent(!isAgent)
    }

    const handleTabChange = (data) => {
        setActiveTab(data)
    }

    const data = [
        {
            image: CardSamplePic,
            drivername: "Ramesh Vilapam",
            vehicleType: "78",
            status: "12k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Suresh Kondeti",
            vehicleType: "345",
            status: "67k",
            createdDate: '24-Aug-2021 | 19:24',

        },
        {
            image: CardSamplePic,
            drivername: "Vijay Anuvula",
            vehicleType: "34",
            status: "23k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Arjun Shyam",
            vehicleType: "52",
            status: "32k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Rajesh Reddy",
            vehicleType: "54",
            status: "38k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Soma Suresh",
            vehicleType: "75",
            status: "09k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Ramesh Vilapam",
            vehicleType: "34",
            status: "89k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Suresh Kondeti",
            vehicleType: "75",
            status: "23k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Vijay Anuvula",
            vehicleType: "35",
            status: "77k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Arjun Shyam",
            vehicleType: "65",
            status: "23k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Rajesh Reddy",
            vehicleType: "57",
            status: "53k",
            createdDate: '24-Aug-2021 | 19:24',
        },
    ];

    const headers = [
        {
            headerName: "User Name",
            headerId: "drivername",
        },
        {
            headerName: "Total trips",
            headerId: "vehicleType",
        },
        {
            headerName: "Commission",
            headerId: "status",
        },
        {
            headerName: "Created Date",
            headerId: "createdDate",
        },
        {
            headerName: "Actions",
            headerId: "actions",
        },
    ];


    return (
        <div className='flex bg-zinc-200 h-[100vh]'>
            <NavBar active={"Agents"} role={role} />
            <div className='flex w-full h-full'>
                <div className='flex flex-col w-full h-full'>
                    <Header title={"Welcome"} />
                    <div className='flex h-[88vh]  w-full pl-3 pr-4'>
                        <div className='rounded-xl mb-1 w-full bg-white p-4'>
                            <DynamicTable
                                data={data}
                                headers={headers}
                                search={true}
                                option1='All'
                                buttondropdown={true}
                                handleButtonClick={handleButtonClick}
                                buttonstyle='py-2'
                                label='Add Agent'
                                width={"w-42"}
                                heightDesktop={"h-[50%]"}
                                nthChildWidth={"[&>*:nth-child(1)]:w-48 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-36 [&>*:nth-child(4)]:w-40 [&>*:nth-child(5)]:w-32"}
                                nthChildStyle={
                                    ""
                                }
                                handleToggleClick={(row, isActive) => {
                                    console.log(isActive, row);
                                }}
                                handleEyeClick={(row) => {
                                    console.log(row);
                                }}
                                handleEditClick={(row) => {
                                    console.log(row);
                                }}
                            />
                        </div>
                    </div>

                </div>
                <ActiveAndInActiveComp
                    shortBar={shortBar}
                    setShortBar={setShortBar}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleTabChange={handleTabChange}
                />
            </div>


            <AddAgentModal
                closeModal={handleButtonClick}
                setIsOpen={setIsAgent}
                isOpen={isAgent}
            />

        </div>
    )
}

export default Agents