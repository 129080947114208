import React, { useState } from 'react'
import NavBar from '../../components/Universal/NavBar'
import Header from '../../components/Universal/Header'
import ActiveAndInActiveComp from '../../components/Common/ActiveAndInActiveComp'
import DynamicTable from '../../components/Universal/DynamicTable'
import CardSamplePic from '../../assets/images/CardSamplePic.png'

const Drivers = ({ role, shortBar, setShortBar }) => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabChange = (data) => {
        setActiveTab(data)
    }

    const data = [
        {
            image:CardSamplePic ,
            drivername: "Ramesh Vilapam",
            vehicleType: "Omni BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Scheduled",
            
        },
        {
            image:CardSamplePic ,
            drivername: "Suresh Kondeti",
            vehicleType: "Tempo ALS/BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "In-Progress",
        },
        {
            image:CardSamplePic ,
            drivername: "Vijay Anuvula",
            vehicleType: "Omni BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image:CardSamplePic ,
            drivername: "Arjun Shyam",
            vehicleType: "Tempo",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image:CardSamplePic ,
            drivername: "Rajesh Reddy",
            vehicleType: "Omni BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image:CardSamplePic ,
            drivername: "Soma Suresh",
            vehicleType: "Omni ALS/BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image:CardSamplePic ,
            drivername: "Ramesh Vilapam",
            vehicleType: "Omni BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image:CardSamplePic ,
            drivername: "Suresh Kondeti",
            vehicleType: "Tempo BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image:CardSamplePic ,
            drivername: "Vijay Anuvula",
            vehicleType: "Omni BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image:CardSamplePic ,
            drivername: "Arjun Shyam",
            vehicleType: "Tempo BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image:CardSamplePic ,
            drivername: "Rajesh Reddy",
            vehicleType: "Omni BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
    ];

    const headers = [
        {
            headerName: "Driver Name",
            headerId: "drivername",
        },
        {
            headerName: "Vehicle Type",
            headerId: "vehicleType",
        },
        {
            headerName: "Status",
            headerId: "status",
        },
        {
            headerName: "Created Date",
            headerId: "createdDate",
        },
        {
            headerName: "Actions",
            headerId: "actions",
        },
    ];


    return (
        <div className='flex bg-zinc-200 h-[100vh]'>
            <NavBar active={"Drivers"} role={role} />
            <div className='flex w-full h-full'>
                <div className='flex flex-col w-full h-full'>
                    <Header title={"Welcome"} />
                    <div className='flex h-[88vh]  w-full pl-3 pr-4'>
                        <div className='rounded-xl mb-1 w-full bg-white p-4'>
                            <DynamicTable
                                data={data}
                                headers={headers}
                                search={true}
                                option1='All'
                                Dropdown={true}
                                width={"w-42"}
                                heightDesktop={"h-[50%]"}
                                nthChildWidth={"[&>*:nth-child(1)]:w-48 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-36 [&>*:nth-child(4)]:w-40 [&>*:nth-child(5)]:w-32"}
                                nthChildStyle={
                                    ""
                                }
                                handleToggleClick={(row, isActive) => {
                                    console.log(isActive, row);
                                }}
                                handleEyeClick={(row) => {
                                    console.log(row);
                                }}
                                handleEditClick={(row) => {
                                    console.log(row);
                                }}
                            />
                        </div>
                    </div>

                </div>
                <ActiveAndInActiveComp
                    shortBar={shortBar}
                    setShortBar={setShortBar}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleTabChange={handleTabChange}
                />
            </div>
        </div>
    )
}

export default Drivers;
